/* mobile: '320px' tablet: '600px', laptop: '1150px',*/
/*-------------------------Mobile----------------------------------*/

.card {
    position: relative;
    max-width: 100%;
    margin: auto;
    overflow: hidden;
}
  
.media {
    height: 250px;
    position: relative;
}
  
.media::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
  
.avatarContainer {
    position: absolute;
    top: 85%;
    left: 10px;
    transform: translateY(-50%);
}
  
.avatar {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 3px solid /* couleur du texte du thème */;
}
  
.userDetails {
    position: absolute;
    bottom: 10%;
    left: 90%;
    /*border: 2px solid black;*/
    transform: translate(-20%, -50%);
    text-align: center;
    color: #FFFFFF;
    text-align: left;
}

.information {
    background: #111439;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 20px 20px;
    font-size: 18px;
}

.containerPersonnel {
    display: flex;
    flex-direction: column;
}

.containerIdentity {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 40px 10px 40px;
    flex-wrap: wrap;
}

.containerSetting {
    width: 100%;
}

.identity {
    /*border: 1px solid black;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 4px;
}

#updProfile {
    background-color: transparent;
    border: 1px solid red;
    color: #111439;
    font-weight: bold;
    padding: 10px 20px;
    text-decoration: none;
}

#updProfile:hover {
    background-color: red;  
    color: #FFFFFF; 
    text-decoration: none;
}

@media all and (min-width: 360px) {
    .userDetails {
        left: 55%;
    }
}
  
/*-------------------------Tablet----------------------------------*/


@media all and (min-width: 600px) {
    .userDetails {
        left: 28%;
    }
    .identity {
        width: 40%;
    }
    .containerIdentity {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

/*-------------------------Laptop----------------------------------*/


@media all and (min-width: 1150px) {
    .userDetails {
        left: 25%;
    }
}